var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"MatchesTable",staticClass:"elevation-1",attrs:{"item-key":"name","loading":_vm.isLoading,"loading-text":_vm.$t('misc.LoadText'),"headers":_vm.headers,"items":_vm.matches,"sort-by":['id'],"sort-desc":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.match_status != 'Cancelled')?_c('router-link',{attrs:{"to":{ path: '/match/' + item.id }}},[_vm._v(" "+_vm._s(item.id)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/user/' + item.user_id }}},[_vm._v(" "+_vm._s(item.owner)+" ")])]}},{key:"item.team1_string",fn:function(ref){
var item = ref.item;
return [(item.team1_id !== null)?_c('router-link',{attrs:{"to":{ path: '/teams/' + item.team1_id }}},[_vm._v(" "+_vm._s(item.team1_string)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.team1_string)+" ")])]}},{key:"item.team2_string",fn:function(ref){
var item = ref.item;
return [(item.team2_id !== null)?_c('router-link',{attrs:{"to":{ path: '/teams/' + item.team2_id }}},[_vm._v(" "+_vm._s(item.team2_string)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.team2_string)+" ")])]}},{key:"top",fn:function(){return [(_vm.isMyMatches && _vm.isThereCancelledMatches)?_c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-btn',{attrs:{"primary":"","loading":_vm.deletePending},on:{"click":_vm.deleteCancelled}},[_vm._v(" "+_vm._s(_vm.$t("Matches.DeleteButton"))+" ")])],1)],1)],1):_c('div')]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }